$white: #FFFFFF;
$white-10: rgba(255, 255, 255, 0.1);
$white-20: rgba(255, 255, 255, 0.2);
$white-30: rgba(255, 255, 255, 0.3);
$green: #33c9c0;
$black: #000000;

$black-20: rgba(0, 0, 0, 0.2);
$black-30: rgba(0, 0, 0, 0.3);
$black-40: rgba(0, 0, 0, 0.4);
$black-50: rgba(0, 0, 0, 0.5);
$black-60: rgba(0, 0, 0, 0.6);

$breakpoint-xs: 374px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;
$breakpoint-xxxl: 1600px;

$breakpoint-sm-max: 575px;
$breakpoint-md-max: 767px;
$breakpoint-lg-max: 991px;
$breakpoint-xl-max: 1199px;
$breakpoint-xxl-max: 1399px;
$breakpoint-xxxl-max: 1599px;

$header-bg: #000;
$bg-color: #000;
$primary-color: #31C2B9;
$primary-32: rgba(51, 201, 192, .32);
$text-color: #fff;
$text-color-40: rgba(255, 255, 255, 0.4);
$text-muted: rgba(255, 255, 255, 0.56);

$card-color: rgba(255, 255, 255, 0.04);
$white-40: rgba(255, 255, 255, .4);
$white-64: rgba(255, 255, 255, .64);
$white-80: rgba(255, 255, 255, .8);

$dark: #1c253a;
$orange: #F2994A;
$red: #B00020;

$error: #B00020;
$warning: #F2994A;
$success: #31C2B9;
$info: rgba(5, 27, 82, 0.40);

$border-color: rgba(5, 27, 82, 0.16);
$border-radius: 8px;
