@import './variables';

.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background: linear-gradient(90deg, #F69C29 0%, #F55376 32%, #7B61FF 64%, #6FCFB2 100%);
}
.mat-tab-header-pagination {
  box-shadow: none!important;
  &-after {
    transform: translateX(10px)!important;
  }
  &-before {
    transform: translateX(-10px)!important;
  }
}
.mat-tab-group {
  .mat-tab-nav-bar,
  .mat-tab-header {
    border-bottom: 1px solid rgba(59, 69, 91, 0.08);
  }
  .mat-tab-label {
    padding: 0;
    height: 56px;
    opacity: 1;
    min-width: initial;
    color: rgba($text-color, .4);
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-right: 32px;

    &:hover {
      color: rgba($text-color, .64);
    }
    &-active {
      color: $text-color;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.fullpage-tabs {
  .mat-tab-header {
    padding: 0 20px;
    background: rgba(255, 255, 255, 0.04);
  }
  .mat-tab-body-content {
    overflow: hidden;
    padding: 40px;
  }
  &.deals-tabs .mat-tab-body-content {
    padding: 40px 20px;
  }

  @media (min-width: $breakpoint-xl) {
    .mat-tab-header {
      padding: 0 40px;
    }
    &.deals-tabs .mat-tab-body-content {
      padding: 40px;
    }
  }
}


.nav-tabs {
  display: flex;
  align-items: flex-end;
  border-color: rgba(59, 69, 91, 0.08);
}
.nav-tabs .nav-link {
  background: none;
  color: #9097c1;
  border-radius: 0;
  border: none;
  padding: 20px 0;
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
}
.nav-tabs .nav-item + .nav-item {
  margin-left: 32px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: $text-color;
  background: none;
  box-shadow: inset 0 -3px 0 $primary-color;
}
.nav-tabs-filled .nav-link {
  background: rgba(255, 255, 255, 0.04);
  color: $text-muted;
  padding: 20px 16px;
  line-height: 1;
  border-radius: 8px 8px 0 0;
  border: none;
}
.nav-tabs-filled .nav-link:hover {
  background: rgba(255, 255, 255, 0.08);
}
.nav-tabs-filled .nav-link.active {
  background: $primary-color;
  color: #ffffff;
  box-shadow: none;
}
.nav-tabs-filled .nav-item + .nav-item {
  margin-left: 4px;
}
.tab-text {
  padding: 20px;
  font-size: 14px;
}

@media (max-width: 767px) {
  .nav-tabs-responsive-md {
    flex-wrap: wrap;
  }
  .nav-tabs-responsive-md.nav-tabs-filled .nav-link {
    border-radius: 8px;
  }
  .nav-tabs-responsive-md .nav-link,
  .nav-tabs-responsive-md .nav-item {
    width: 100%;
  }
  .nav-tabs-responsive-md .nav-item+.nav-item {
    margin: 6px 0 0;
  }
  .create-deal-tab-nav.nav-tabs-responsive-md {
    padding-bottom: 20px;
  }
}
