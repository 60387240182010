@import './variables';

.card {
  display: block;
  position: relative;
  background: $card-color;
  border: 2px solid rgba(255, 255, 255, 0.20);
  box-shadow: 0 16px 20px 0 rgba(59, 69, 91, 0.04);
  border-radius: 8px;
  transition: all 0.3s;

  &.cursor-pointer:hover {
    background: rgba(255, 255, 255, 0.08);
    box-shadow: 0 24px 32px rgba(59, 69, 91, 0.08);
  }

  &-header {
    background: none;
    padding: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 700;

    &.border-none {
      border-bottom: none;
    }
  }
  &-content {
    padding: 24px;
    background: none;
  }
  &-footer {
    background: none;
    padding: 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    &.border-none {
      border-top: none;
    }
  }



  &.not-found {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $text-color-40;
    font-size: 16px;

    .icon {
      display: inline-block;
      margin-bottom: 12px;
      font-size: 46px;
      line-height: 1;
      color: $text-color-40;
    }

    @media (min-width: $breakpoint-lg) {
      font-size: 24px;

      .icon {
        font-size: 64px;
      }
    }
  }
}


.card-list {
  display: flex;
  flex-wrap: wrap;

  .card {
    width: 100%;
    flex: 0 0 100%;
    margin-bottom: 20px;
  }

  @media (min-width: $breakpoint-lg) {
    &.per-row-2 {
      .card,
      .card:nth-child(odd) {
        margin-right: 20px;
        width: calc(50% - 10px);
        flex: 0 0 calc(50% - 10px);
      }
      .card:nth-child(even) {
        margin-right: 0;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1023px),
  (min-width: 1024px) and (max-width: 1365px) {
    &.per-row-3 {
      .card,
      .card:nth-child(odd) {
        margin-right: 20px;
        width: calc(50% - 10px);
        flex: 0 0 calc(50% - 10px);
      }

      .card:nth-child(even) {
        margin-right: 0;
      }
    }
  }
  @media (min-width: 1366px) {
    &.per-row-3 {
      .card,
      .card:nth-child(odd) {
        margin-right: 20px;
        width: calc(33.33% - 14px);
        flex: 0 0 calc(33.33% - 14px);;
      }

      .card:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}



