@import './variables';

.alert {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: none;
  background: none;
  padding: 8px 20px;
  border-radius: $border-radius;
  margin: 0;
  color: $text-color;

  &-icon {
    flex: 0 0 24px;
    font-size: 24px;
    margin-right: 12px;
  }
  &-heading {
    max-width: calc(100% - 36px);
  }
  &-button {
    margin: 12px 0 0;
    width: 100%;

    @media (min-width: $breakpoint-sm) {
      width: auto;
      margin: 0 0 0 auto;
    }
  }
  &-info {
    .alert-icon {
      color: rgba(255, 255, 255, 0.5);;
    }
  }
  &-warning {
    .alert-icon {
      color: $warning;
    }
  }
  &-error {
    .alert-icon {
      color: $error;
    }
  }
  &-success {
    .alert-icon {
      color: $success;
    }
  }

  p {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .alert-button,
  .alert-button .btn {
    width: 100%;
  }
}

.section-alert {
  .alert {
    padding: 8px 20px;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 0;

    @media (min-width: $breakpoint-sm) {
      min-height: 56px;
    }
    @media (min-width: $breakpoint-xl) {
      padding: 8px 40px;
    }
  }
}
