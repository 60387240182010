@import './variables';

body {
  .mat-form-field-type-mat-select {

    &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label.mat-form-field-empty {
      transform: none;
    }
    .mat-select-arrow-wrapper {
      transform: translateY(-8px);
    }
  }
  .mat-form-field.mat-focused {
    &.mat-primary .mat-select-arrow {
      color: $text-color;
    }
    .mat-select-arrow-wrapper {
      transform: translateY(-8px) scale(1,-1);
    }
  }

  .mat-select-arrow {
    border: none;
    width: 24px;
    height: 24px;
    margin: 0;

    &:after {
      content: "\e969";
      font-family: 'icomoon';
      font-size: 24px;
    }
  }

  .mat-select-value {
    color: $text-color;
  }

  .mat-select-panel {
    margin-top: 32px;
    filter: drop-shadow(0px 24px 32px rgba(59, 69, 91, 0.08));
    box-shadow: 0 16px 20px rgba(59, 69, 91, 0.04)!important;
    border-radius: 0 0 $border-radius $border-radius!important;
    max-height: 280px;

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background-color: #211f1f;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: $border-radius;
      background-color: #a99f9f;
    }

    .mat-option {
      padding: 10px 16px;
      font-size: 16px;
      line-height: 24px;
      height: 56px;
      transition: background 0.3s;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), #000;
      color: $text-color;

      .mat-option-text {
        display: flex;
        align-items: center;

        img {
          width: 24px;
          height: 24px;
          object-fit: contain;
          margin-right: 10px;
        }
      }
      &:hover {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.26) 0%, rgba(255, 255, 255, 0.26) 100%), #000;
      }

      &.mat-selected:not(.mat-option-multiple) {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.26) 0%, rgba(255, 255, 255, 0.26) 100%), #000;
      }
    }
  }
}
