// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

$bull-green: (
  50: #e0f2f1,
  100: #b2dfdb,
  200: #74DAD4,
  300: #74DAD4,
  400: #26a69a,
  500: #31C2B9,
  600: #289FA6,
  700: #289FA6,
  800: #289FA6,
  900: #289FA6,
  A100: #a7ffeb,
  A200: #64ffda,
  A400: #1de9b6,
  A700: #00bfa5,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);
$theme-color-primary: mat-palette($bull-green);
$theme-color-accent: mat-palette($mat-orange);
$theme-color-warn: mat-palette($mat-red);

$custom-theme: mat-light-theme((
  color: (
    primary: $theme-color-primary,
    accent: $theme-color-accent,
    warn: $theme-color-warn,
  ),
));

$custom-typography: mat-typography-config(
  $font-family: 'Ubuntu',
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@include angular-material-theme($custom-theme);
@include angular-material-typography($custom-typography);

@import "./styles/variables";
@import "./styles/reboot";
@import "./styles/grid";
@import "./styles/typography";
@import './styles/input';
@import './styles/select';
@import './styles/button';
@import './styles/table';
@import './styles/tabs';
@import "./styles/tooltip";
@import "./styles/dlg";
@import "./styles/card";
@import "./styles/progress";
@import "./styles/deals";
@import "./styles/tiers";
@import "./styles/alert";
@import "./styles/mat-panel";

// @import './styles/functions';


/*--- GENERAL ---*/
body, html {
  min-height: 100%;
  height: 100%;
}
body {
  background-color: $bg-color;
}

.bg-white {
  background: $white;
}
.bg-dark {
  background: $dark!important;
}



/*----------------------------------------------------
  2. GRID
------------------------------------------------------*/
.logo-24 {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 100%;
}
.logo-20 {
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 100%;
}
.logo-sm {
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.logo-md {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.logo-sm img,
.logo-md img,
.contain-img img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
@media (min-width: 768px) {
  .convert-value {
    display: inline-block;
  }
}

/*----------------------------------------------------
  5. FORM
------------------------------------------------------*/
.tier-control-group {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
}
.tier-control-group .tier-control {
  flex: 0 0 auto;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0 12px;
}
.tier-control {
  display: flex;
  align-items: center;
}
.tier-control__icon {
  width: 32px;
  margin-right: 8px;
}
.tier-control__input {
  width: calc(100% - 40px);
}
@media (min-width: 768px) {
  .tier-control-group .tier-control {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .form-group-inline .white-card__header {
    margin-right: auto;
    border-bottom: 0;
  }
  .form-group-inline .white-card__content {
    padding: 12px 24px 12px 0;
  }
  .form-group-inline label {
    margin-bottom: 0;
  }
  .tier-control-group .tier-control {
    width: 100%;
  }
}

@media (min-width: 1280px) {
  .tier-control-group .tier-control {
    width: 50%;
  }
}

/*----------------------------------------------------
  6. MEDIA UPLOAD
------------------------------------------------------*/
.upload-control {
  background: rgba(28, 37, 58, 0.02);
  background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' rx='8' ry='8' style='fill: none; stroke: rgba(28, 37, 58, 0.32); stroke-width: 1; stroke-dasharray: 8 8'/></svg>");
  border-radius: 8px;
  height: 235px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
}
.upload-image__title,
.upload-placeholder {
  opacity: 0.4;
  font-weight: 600;
  font-size: 12px;
  margin-top: 10px;
}
.upload-image img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  object-fit: contain;
}
.upload-placeholder .icon {
  font-size: 40px;
}
.upload-control__btn {
  margin-top: 1em;
}

/*----------------------------------------------------
  7. DROPDOWN
------------------------------------------------------*/
.btn.dropdown-toggle {
  text-align: left;
  padding-right: 50px;
  padding-left: 24px;
  z-index: 1001;
  position: relative;
}
.btn-icon.dropdown-toggle {
  justify-content: flex-start;
}
.dropdown-toggle::after {
  content: "\e969";
  font-family: "icomoon";
  border: none;
  vertical-align: middle;
  font-size: 12px;
  position: absolute;
  right: 27px;
  top: 50%;
  margin-top: -8px;
}
.dropdown-menu {
  min-width: 100%;
  border: none;
  background: #ffffff;
  box-shadow: 0 4px 12px rgba(59, 69, 91, 0.12);
  border-radius: 0 0 1.5rem 1.5rem;
  padding: 0;
  overflow: hidden;
  z-index: 250;
}
.btn.dropdown-toggle + .dropdown-menu {
  padding: 3rem 0 0;
  border-radius: 1.5rem;
  transform: translateY(0)!important;
}
.dropdown-item {
  font-size: 14px;
  line-height: 1.44;
  font-weight: 600;
  color: $text-color;
  padding: 14px 24px;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: $text-color;
  background: rgba(28, 37, 58, 0.08);
}



/*----------------------------------------------------
  7. ASIDE
------------------------------------------------------*/
.nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.dashboard-aside {
  top: 72px;
  color: #ffffff;
  border-right: none;
  z-index: 5000;
}
.dashboard-aside.active {
  transform: translateX(0);
}
.dashboard-logo {
  padding: 1.5rem;
}
.dashboard-nav .nav-item {
  display: flex;
  align-items: center;
  width: 100%;
  flex: 0 0 100%;
  padding: 1rem 1.5rem;
  transition: all 0.2s;
}
.dashboard-nav .nav-link {
  color: #ffffff;
  padding: 0;
  flex: 1 0 0%;
}
.dashboard-nav .nav-item:hover,
.dashboard-nav .nav-item.active {
  background: rgba(78, 255, 212, 0.08);
}
.nav-link__icon {
  margin-right: 0.7rem;
}
.nav-link__action {
  opacity: 0;
  visibility: hidden;
  margin-left: auto;
  color: #4effd4;
  font-size: 24px;
  transition: all 0.3s;
}
.nav-item:hover .nav-link__action {
  opacity: 1;
  visibility: visible;
}
.nav-link__action:hover {
  color: $primary-color;
}
.desktop-logo {
  display: none;
}
.mob-menu-toggle {
  display: inline-block;
  padding: 0;
  border: none;
  position: relative;
  width: 28px;
  height: 18px;
  background: none;
  opacity: 0.4;
  transition: opacity 0.3s;
}
.mob-menu-toggle:hover {
  opacity: 0.8;
}
.mob-menu-toggle b {
  position: absolute;
  display: block;
  height: 2px;
  width: 100%;
  left: 0;
  top: 50%;
  background: #ffffff;
  margin-top: -1px;
  transform: rotate(0);
  transition: all 0.3s;
}
.mob-menu-toggle b:before,
.mob-menu-toggle b:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  margin-top: -1px;
  position: absolute;
  right: 0;
  background: #ffffff;
}
.mob-menu-toggle b:before {
  top: -7px;
  transform: rotate(0);
  transition: all 0.3s;
}
.mob-menu-toggle b:after {
  top: 9px;
  width: 80%;
}

.mob-menu-header {
  height: 72px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 22px 20px;
  color: #ffffff;
  z-index: 1050;
  background: black;

  &__title {
    margin: 0 0 0 1.5rem;
    line-height: 1.4;
  }
}
.mobile-logo {
  margin-left: auto;
}
.dashboard-role {

}

.sidenav {
  transform: translateX(-100%);
  visibility: hidden;
  transition: transform 0.3s;
}

@media (max-width: 1199px) {
  .mob-menu-active {
    overflow: hidden;

    .sidenav {
      transform: translateX(0);
      visibility: visible;
    }
    .mob-menu-toggle b {
      transform: rotate(-45deg);
      &:before {
        transform: rotate(90deg);
        top: 1px;
      }
      &:after {
        opacity: 0;
      }
    }
  }
}
@media (min-width: $breakpoint-xl) {
  .sidenav {
    transform: translateX(0);
    visibility: visible;
  }
  .mob-menu-header {
    display: none;
  }
  .mob-menu-toggle,
  .mobile-logo {
    display: none;
  }
  .desktop-logo {
    display: block;
  }
}

/*----------------------------------------------------
  3. CONTENT
------------------------------------------------------*/
.cursor-pointer {
  cursor: pointer;
}
.dashboard-content {
  padding: 72px 0 0;
}
.dashboard-section {
  padding-right: 20px;
  padding-left: 20px;
}
.dashboard-white {
  box-shadow: 0 4px 8px rgba(59, 69, 91, 0.04);
  padding: 12px 20px;

  @media (min-width: $breakpoint-lg) {
    padding: 12px 40px;
  }
}
.white-card {
  border: 2px solid rgba(255, 255, 255, 0.20);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), #000;
  box-shadow: 0 24px 32px 0 rgba(59, 69, 91, 0.08);
  border-radius: 16px;
}
.white-card__header {
  border-bottom: 1px solid rgba(59, 69, 91, 0.08);
}
.form-group-inline .white-card__header {
  border-bottom: 0;
}
.white-card__header,
.white-card__content,
.white-card__footer {
  padding: 1.5rem;
}
.white-card__alerts .alert {
  border-radius: 0;
}
.white-card__footer {
  border-top: 1px solid rgba(59, 69, 91, 0.08);
}
.coin-title {
  display: flex;
  align-items: center;
}
.coin-title__logo {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: rgba(98, 126, 234, 0.16);
  border-radius: 100%;
}
.coin-title__logo img {
  object-fit: contain;
}
.coin-title__title {
  padding-left: 12px;
}


@media (min-width: $breakpoint-md){
  .white-card-md {
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(59, 69, 91, 0.04);
    border-radius: 16px;
  }
}

@media (min-width: $breakpoint-xl) {
  .dashboard-content {
    padding: 0 0 0 252px;
  }
  .dashboard-section {
    padding-right: 40px;
    padding-left: 40px;
  }
}

/*----------------------------------------------------
  7. CONTENT HEADER
------------------------------------------------------*/

.token-address {
  display: inline-block;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
  padding: 8px 10px 8px 8px;
}

.copy-icon {
  font-size: 24px;
  padding: 0;
  border: none;
  background: no-repeat;
  transition: color 0.2s ease-out;
}
.copy-icon:hover {
  color: $primary-color;
}

.select-coin-dropdown .btn {
  min-width: 100%;
  z-index: 300;
}
.deal-progress-group {
  width: 100%;
  margin-top: 10px;
}

@media (min-width: 767px) {
  .select-coin-dropdown .btn {
    min-width: initial;
  }
}

@media (min-width: 992px) {
  .deal-progress-group {
    width: 50%;
    margin-left: auto;
    margin-top: 0;
  }
}
.wallet-address {
  background: rgba(5, 27, 82, 0.04);
  color: $text-color;
  border-radius: 24px;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
}
@media (min-width: 1280px) {
  .wallet {
    min-width: 360px;
  }
  .select-coin-dropdown .btn {
    min-width: 180px;
  }
}

/*----------------------------------------------------
  8. WELCOME
------------------------------------------------------*/
.penalties-table td,
.penalties-table th {
  padding: 1.5rem;
  line-height: 1;
}
.penalties-table td:last-child {
  text-align: right;
}
.notification-box .alert {
  margin: 0 0 24px;
}
.notification-box .alert:last-child {
  margin-bottom: 40px;
}

/*----------------------------------------------------
  8. DEALS
------------------------------------------------------*/

.avatar img {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  object-fit: cover;
}
.badge-status {
  position: relative;
  border-radius: 32px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 16px 8px 24px;
  background: rgba(5, 27, 82, 0.08);
}
.badge-status:before {
  content: "";
  width: 8px;
  height: 8px;
  display: block;
  position: absolute;
  left: 8px;
  top: 12px;
  border-radius: 100%;
  background: #051b52;
}
.badge-status.status-filled {
  background: rgba(51, 201, 192, 0.08);
  color: #33c9c0;
}
.badge-status.status-filled:before {
  background: #33c9c0;
}

.progress-legend-bull {
  margin-top: -52px;
}
.progress-legend__bull {
  flex: 1 0 0%;
  text-align: center;
  position: relative;
}
.progress-legend__bull > * {
  transform: translateX(50%);
  text-align: center;
  display: block;
  margin-left: auto;
  max-width: 50px;
}
.legend-bull-title:before {
  content: "";
  height: 34px;
  width: 2px;
  background: #ffffff;
  display: block;
  margin: 0 auto;
}
.progress-legend__bull:last-child .legend-bull-title:before {
  background: none;
}
.legend-bull-title {
  font-weight: 600;
  line-height: 1;
}
.legend-bull-value {
  line-height: 1;
  margin-top: 4px;
}
.progress-bull-total {
  padding-top: 45px;
  position: relative;
  padding-right: 45px;
  overflow: hidden;
}
.progress-bull-total .progress {
  height: 8px;
}
.status-upcoming .badge-status:before,
.status-progress .badge-status:before {
  background: var(--orange);
}
.status-filled .badge-status:before {
  background: $primary-color;
}
.tab-section,
.deals-type-tab,
.deals-cat-tab {
  padding: 17px 20px 0;
  border-bottom: 1px solid rgba(59, 69, 91, 0.08);
}
.deals-cat-tab {
  padding-top: 15px;
}
.deals-cat-content {
  padding: 20px;
}

.white-card__header.col-12 {
  padding-bottom: 0;
}

@media (min-width: $breakpoint-md) {
  .progress-legend__bull > * {
    max-width: initial;
  }
  .white-card__header.col-12 {
    padding: 1.5rem;
  }
}
@media (min-width: $breakpoint-xl) {
  .deals-cat-content {
    padding: 40px;
  }
  .tab-section,
  .deals-type-tab,
  .deals-cat-tab {
    padding: 17px 40px 0;
  }
}
@media (min-width: $breakpoint-xl) {
  .progress-bull-total {
    padding-right: 75px;
  }
}

/*----------------------------------------------------
  8. DEAL DETAIL
------------------------------------------------------*/
.deal-detail-header {
  padding-top: 40px;
}
.deal-detail-card .deal-header {
  padding: 20px 20px 0;
}
.deal-join {
  padding: 23px 20px 19px;
  display: flex;
  flex-wrap: wrap;
}
.deal-join > .btn {
  width: 100%;
  margin: 0 0 10px;
}
.deal-join > .btn:first-child {
  margin-left: 0;
}
.deal-join > .btn:last-child {
  margin-right: 0;
}
.deal-swap__amount {
  padding: 20px 20px 26px;
}
.deal-swap__progress {
  border-top: 1px solid rgba(59, 69, 91, 0.08);
  padding: 15px 20px 20px;
}
.deal-info {
  padding-top: 24px;
}
.deal-info-nav {
  padding: 14px 20px 0;
  border-bottom: 1px solid rgba(59, 69, 91, 0.08);
}
@media (min-width: 768px) {
  .deal-join > .btn {
    flex: 1 0 0;
    margin: 0 5px;
  }
}
@media (min-width: 992px) {
  .swap-progress {
    width: 64%;
    flex: 0 0 64%;
    padding-left: 2rem;
  }
}
@media (min-width: 1280px) {
  .deal-detail-header__left,
  .deal-detail-header__right {
    flex: 0 0 auto;
    width: 50%;
  }
}

/*----------------------------------------------------
  8. REGISTRATION
------------------------------------------------------*/
.sign-up-form {
  max-width: 544px;
  margin: 0 auto;
}
.sign-up-form img {
  margin-bottom: 1.5em;
  max-width: 72px;
}

/*----------------------------------------------------
  8. ADMIN
------------------------------------------------------*/
.deal-actions {
  display: inline-flex;
  align-items: center;
  padding: 24px 24px 0;
}
.deal-actions .btn-circle + .btn-circle {
  margin-left: 0.75em;
}

.users-list {
  table-layout: fixed;
}
.users-list td,
.users-list th {
  padding: 12px;
  vertical-align: middle;
}
.users-list th {
  line-height: 32px;
}
.users-list td:first-child,
.users-list th:first-child {
  padding-left: 24px;
}
.users-list td:last-child,
.users-list th:last-child {
  padding-right: 24px;
}
.users-list a {
  color: inherit;
}
.users-list th:first-child {
  width: 70px;
}
.update-val {
  display: block;
}
@media (max-width: 991px) {
  .update-val + input {
    width: 100%;
    margin-top: 16px;
  }
}
@media (min-width: 992px) {
  .update-val {
    margin: 0 0 8px;
  }
  .deal-actions {
    position: absolute;
    right: 24px;
    top: 26px;
    padding: 0;
  }
  .create-deal-tab-content .tab-pane.active,
  .create-deal-form {
    min-height: calc(100vh - 72px);
    display: flex;
    flex-direction: column;
  }
  .create-deal-tab-content .tab-pane.active {
    min-height: calc(100vh - 144px);
  }
}

/*----------------------------------------------------
  SOCIAL
------------------------------------------------------*/
.social-list {
  display: flex;
  align-items: center;

  img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    transform: none;
    transform-origin: 50%;
    transition: all 0.3s;
  }
  a {
    display: block;
    + a {
      margin-left: 16px;
    }

    &:hover img {
      transform: scale(1.1);
    }
  }
}

.deal-thumb-preview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 100%;
}

.overflow-ellipsis {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.address-btn {
  display: block;
  position: relative;
  padding: 10px 12px 10px 49px;
  background: rgba(248, 250, 255, 0.04);
  border: none;
  text-align: left;
  border-radius: 8px;
  white-space: nowrap;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  letter-spacing: 0.25px;
  color: $text-color;

  span {
    display: block;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:before {
    font-family: 'icomoon';
    position: absolute;
    left: 12px;
    opacity: 0.4;
    font-size: 24px;
  }

  &:hover {
    background: rgba(248, 250, 255, 0.08);
    //&:before {
    //  color: $primary-color;
    //}
  }

  &.--link:before {
      content: "\e972";
  }
  &.--copy {
    &:before {
      content: "\e965";
    }
    &:after {
      content: "✔ Copied";
      display: block;
      font-size: 14px;
      line-height: 20px;
      padding: 8px 12px;
      color: #FFFFFF;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #1C253A;
      border-radius: 8px;
      position: absolute;
      opacity: 0;
      visibility: hidden;
      top: -5px;
      left: 50%;
      transform: translate(-50%, -100%);
    }
    &:focus:after {
      animation: copied 2s;
    }
  }
}

@keyframes copied {
  0% {
    opacity: 1;
    visibility: visible;
  }
  90% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: visible;
  }
}

.loading-block,
.loading-page {
  position: relative;

  &:before {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(28, 37, 58, .3);
    z-index: 1020;
  }

  &:after {
    content: '';
    border-radius: 50%;
    width: 60px;
    height: 60px;
    font-size: 6px;
    text-indent: -9999em;
    border-top: 1.1em solid #FFFFFF;
    border-right: 1.1em solid #FFFFFF;
    border-bottom: 1.1em solid #FFFFFF;
    border-left: 1.1em solid $primary-color;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: loading-spinner 1.1s infinite linear;
    animation: loading-spinner 1.1s infinite linear;
    position: fixed;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
    z-index: 1021;

    @media (min-width: $breakpoint-xl) {
      left: calc(50% + 96px);
    }
  }
}
.loading-block {
  &:before,
  &:after {
    position: absolute;
  }
}

@-webkit-keyframes loading-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


body .mat-menu-panel {
  filter: drop-shadow(0px 24px 32px rgba(59, 69, 91, 0.08));
  box-shadow: 0 16px 20px rgba(59, 69, 91, 0.04);
  border-radius: 0 0 $border-radius $border-radius;

  .mat-menu-content:not(:empty) {
    padding: 0;
  }

  .mat-menu-item {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    height: auto;
    padding: 16px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), #000;
    color: $text-color;

    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.26) 0%, rgba(255, 255, 255, 0.26) 100%), #000;
    }

    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
      margin-right: 10px;
    }

    .icon {
      margin-right: 10px;
    }
  }
  &.select-network-menu {
    min-width: calc(100vw - 40px);

    @media (min-width: $breakpoint-sm) {
      min-width: 200px;
    }
  }

  &.more-menu {
    min-width: 200px;
    border-radius: $border-radius!important;
    margin-right: 40px;
    margin-top: -40px;
  }
}

.ql-snow {
  .ql-editor {
    .ql-video {
      width: 480px;
      height: 270px;
      max-width: 100%;
      @media (max-width: 500px) {
        height: 200px;
      }
    }
  }

  .ql-tooltip {
    z-index: 100;
  }
}

.user-poll-description {
  img {
    width: 100%;
  }
}
.opacity-50{
  opacity: 0.5 !important;
}

.mat-select-arrow {
  color: $text-color;
}

.mat-paginator {
  background: rgba(255, 255, 255, 0.04);
  color: $text-color;
  border-radius: 8px 8px 0 0;
  .mat-paginator-outer-container {
    .mat-paginator-container {
      .mat-paginator-range-actions {
        gap: 8px;
        .mat-button-disabled {
          opacity: 0.4;
          .mat-paginator-icon {
            color: rgba(255, 255, 255, 0.4);
          }
        }
      }
    }
  }
}

.progress {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.15) 0%, rgba(95, 95, 95, 0.15) 100%);
  border: 2px solid rgba(255, 255, 255, 0.20);
  .progress-label {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.15) 0%, rgba(95, 95, 95, 0.15) 100%);
    color: #FFFFFF;
    .rainbow-border {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(90deg, #F69C29 0%, #F55376 32%, #7B61FF 64%, #6FCFB2 100%);
      border-radius: $border-radius;
      z-index: -1;
      padding: 2px;
      background-clip: padding-box;
      -webkit-mask: linear-gradient(#fff 0%, #fff 100%) content-box, linear-gradient(#fff 0%, #fff 100%);
      mask: linear-gradient(#fff 0%, #fff 100%) content-box, linear-gradient(#fff 0%, #fff 100%);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
    }
  }
}


